import { Link } from "react-router-dom";

function IntroOOP() {
  return (
    <div>
      <h4>Understanding Object-Oriented Programming</h4>
      <hr />
      <div>
        <p>
          Object-oriented programming (OOP) is a programming paradigm that
          focuses on representing real-world objects and their behavior in code.
          In Java, OOP is used extensively, and it revolves around four main
          principles: encapsulation, inheritance, abstraction, and polymorphism.
        </p>
        <h4>Encapsulation</h4>
        <p>
          Encapsulation is the practice of hiding the internal details of an
          object from the outside world and providing a set of methods to
          interact with it. In Java, encapsulation is achieved using access
          modifiers, such as public, private, and protected, to control the
          visibility of fields and methods.
        </p>
        <h4>Inheritance</h4>
        <p>
          Inheritance is a mechanism that allows a new class to be based on an
          existing class, inheriting its properties and behaviors. In Java,
          inheritance is implemented using the "extends" keyword, which allows a
          subclass to inherit methods and fields from its superclass.
        </p>
        <h4>Abstraction</h4>
        <p>
          Abstraction is the practice of modeling complex systems by simplifying
          them and focusing on the essential features. In Java, abstraction is
          achieved using abstract classes and interfaces. An abstract class is a
          class that cannot be instantiated but can be extended, while an
          interface is a collection of abstract methods that define a contract
          for a class to implement.
        </p>
        <h4>Polymorphism</h4>
        <p>
          Polymorphism is the ability of objects of different types to be
          treated as if they are of the same type. In Java, polymorphism is
          achieved through method overloading and method overriding. Method
          overloading allows a class to have multiple methods with the same name
          but different parameters, while method overriding allows a subclass to
          provide its own implementation of a method defined in its superclass.
        </p>
        <p>
          By using these four principles, Java developers can create complex and
          scalable systems that model real-world objects and their behavior in a
          natural and intuitive way. OOP helps to make code more maintainable,
          modular, and easier to understand, making it a popular choice for
          large-scale enterprise applications.
        </p>
      </div>
    </div>
  );
}
export default IntroOOP;
