export default function PythonOOPS() {
  return (
    <div>
      <h4>Understanding Object-Oriented Programming (OOP) in Python</h4>
      <hr />
      <div>
        <p>
          Object-Oriented Programming (OOP) is a programming paradigm that
          focuses on creating reusable code by organizing data and behavior into
          objects. Python supports OOP and allows developers to create classes
          and objects, encapsulate data and behavior, and reuse code through
          inheritance.
        </p>
        <p>Here are the key concepts of OOP in Python:</p>
        <h4>Classes and Objects</h4>
        <p>
          A class is a blueprint or a template for creating objects. It defines
          the attributes and methods that an object of that class will have. An
          object is an instance of a class. 
        </p>

        <h4>Encapsulation</h4>
        <p>
          Encapsulation is the process of hiding the complexity of an object's
          behavior and data from other objects. In Python, you can achieve
          encapsulation by making attributes and methods private, using the
          double underscore prefix (__).
        </p>

        <h4>Inheritance</h4>
        <p>
          Inheritance is a way to create a new class based on an existing class.
          The new class inherits all the attributes and methods of the existing
          class, and can also add new attributes and methods. 
        </p>
        <h4>Polymorphism</h4>
        <p>
          Polymorphism is the ability of objects to take on different forms. In
          Python, you can achieve polymorphism through method overriding and
          method overloading. Method overriding is when a subclass provides a
          different implementation of a method that is already defined in its
          superclass. In the example above, the ElectricCar class overrides the
          get_info method. Method overloading is when a class provides multiple
          methods with the same name but different parameters. In Python, you
          can achieve method overloading by using default parameter values or
          variable-length argument lists. Overall, OOP in Python allows you to
          create code that is more modular, flexible, and reusable. By
          encapsulating data and behavior, you can create objects that are
          easier
        </p>
      </div>
    </div>
  );
}
