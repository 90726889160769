import { Link } from "react-router-dom";

function CreateManageTables() {
  return (
    <div>
      <h4>Creating and managing MySQL tables: How to create tables, add and remove columns, and set up relationships between tables.</h4>
      <hr />
      <h4>Introduction</h4>
      <div>
        <p>
          MySQL is a popular relational database management system that is widely used for web development. Tables are the basic building blocks of any database in MySQL. In this blog post, we will discuss how to create tables in MySQL, add and remove columns, and set up relationships between tables.
        </p>
        <h4>Creating Tables in MySQL</h4>
        <p>
          To create a table in MySQL, you need to use the CREATE TABLE statement. Here's an example:
        </p>
        <p>
          CREATE TABLE users ( id INT AUTO_INCREMENT PRIMARY KEY, name VARCHAR(50) NOT NULL, email VARCHAR(100) NOT NULL UNIQUE, created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP );
        </p>
        <p>
          In this example, we are creating a table called users with four columns:
        </p>
        <p>
          <ul>
            <li>id: An integer column that is the primary key and auto-incremented.</li>
            <li>name: A string column that can hold up to 50 characters and cannot be null.</li>
            <li>email: A string column that can hold up to 100 characters, cannot be null, and must be unique.</li>
            <li>created_at: A timestamp column that defaults to the current date and time.</li>
          </ul>
        </p>
        <h4>Adding and Removing Columns</h4>
        <p>
          You can add or remove columns from an existing table using the ALTER TABLE statement. Here's an example of adding a column to the users table:
        </p>
        <p>
          ALTER TABLE users ADD COLUMN phone VARCHAR(20) AFTER email;
        </p>
        <p>
          In this example, we are adding a phone column to the users table that can hold up to 20 characters and is placed after the email column.
        </p>
        <p>
          To remove a column from a table, use the ALTER TABLE statement with the DROP COLUMN clause. Here's an example:
        </p>
        <p>
          ALTER TABLE users DROP COLUMN phone;
        </p>
        <p>
          In this example, we are removing the phone column from the users table.
        </p>
        <h4>Setting up Relationships Between Tables</h4>
        <p>
          In MySQL, you can set up relationships between tables using foreign keys. Foreign keys are used to link one table to another, creating a parent-child relationship between the tables.
        </p>
        <p>
          Here's an example of creating a foreign key in MySQL:
        </p>
        <p>
          CREATE TABLE orders ( id INT AUTO_INCREMENT PRIMARY KEY, user_id INT, amount DECIMAL(10,2), created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP, FOREIGN KEY (user_id) REFERENCES users(id) );
        </p>
        <p>
          In this example, we are creating a table called orders with four columns:
        </p>
        <ul>
          <li>id: An integer column that is the primary key and auto-incremented.</li>
          <li>user_id: An integer column that will be used as a foreign key to link to the users table.</li>
          <li>amount: A decimal column that can hold up to 10 digits, with 2 decimal places.</li>
          <li>reated_at: A timestamp column that defaults to the current date and time.</li>
        </ul>
        <p>
          The FOREIGN KEY clause specifies that the user_id column is a foreign key that references the id column of the users table. This creates a relationship between the users and orders tables, where each order belongs to a user.
        </p>
        <h4>Conclusion</h4>
        <p>
          Creating and managing tables in MySQL is an essential part of building a relational database. By understanding how to create tables, add and remove columns, and set up relationships between tables, you can create a robust and efficient database structure for your application. Remember to use the appropriate data types for your columns, consider the relationships between tables, and use foreign keys to link your tables together. With these tips, you can master creating and managing tables in MySQL.
        </p>
      </div>
    </div>
  );
}
export default CreateManageTables;
