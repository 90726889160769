import { Link, useMatch, useResolvedPath } from "react-router-dom";

export default function Header({ selectedMenu, mode, toggleMode }) {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light">
        <Link
          to="/"
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none"
        >
          <span
            className="website-title"
          >
            Vishleshak
          </span>
        </Link>
        <ul
          className="nav nav-pills"
          style={{ marginRight: "20px", gap: "1rem", textDecoration: "none" }}
        >
          <CustomLink to="/home"> Home</CustomLink>
          <CustomLink to="/about-us"> About us</CustomLink>
          <CustomLink to="/blogs"> Blogs</CustomLink>
          <CustomLink to="/contact-us"> Contact us</CustomLink>
        </ul>
      </nav>
      <hr />
    </div>
  );
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <li>
      <Link
        to={to}
        {...props}
        className={isActive ? "nav-link active" : "nav-link"} id="gradient-menu"
      >
        {children}
      </Link>
    </li>
  );
}
