import { Link } from "react-router-dom";

function Footer({ mode }) {
  return (
    <footer
      className="py-5"
      // style={{ color: mode === "dark" ? "white" : "black" }}
    >
      <div className="row">
        <div className="col-6 col-md-2 mb-3">
          {/* <h5 style={{ color: mode === "dark" ? "white" : "black" }}>Links</h5> */}
          <h5>Links</h5>
          <ul className="nav flex-column">
            <li className="nav-item mb-2">
              <Link
                to="/home"
                className="nav-link p-0 "
                // style={{ color: mode === "dark" ? "white" : "black" }}
              >
                Home
              </Link>
            </li>
            <li className="nav-item mb-2">
              <Link
                to="/about-us"
                className="nav-link p-0"
                // style={{ color: mode === "dark" ? "white" : "black" }}
              >
                About us
              </Link>
            </li>
            <li className="nav-item mb-2">
              <Link
                to="/blogs"
                className="nav-link p-0 "
                // style={{ color: mode === "dark" ? "white" : "black" }}
              >
                Blogs
              </Link>
            </li>
            <li className="nav-item mb-2">
              <Link
                to="/contact-us"
                className="nav-link p-0 "
                // style={{ color: mode === "dark" ? "white" : "black" }}
              >
                Contact us
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-6 col-md-2 mb-3">
          <h5>Office address</h5>
          <p>
            Office No.801, Global Business Hub, Opposite to EON IT Park, Beside
            World Trade Center, Kharadi, Pune, Maharshtra, India
          </p>
          <p></p>
        </div>
        <div className="col-6 col-md-2 mb-3"></div>
        <div className="col-md-5 offset-md-1 mb-3">
          <form>
            <h5>Subscribe to our newsletter</h5>
            <p>Monthly digest of what's new and exciting from us.</p>
            <div className="d-flex flex-column flex-sm-row w-100 gap-2">
              <label for="newsletter1" className="visually-hidden">
                Email address
              </label>
              <input
                id="newsletter1"
                type="text"
                className="form-control"
                placeholder="Email address"
              />
              <button className="btn btn-primary" type="button">
                Subscribe
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="d-flex flex-column flex-sm-col justify-content-between py-4 my-4 border-top">
        <p>
          &copy; 2023 Vishleshak Technologies Pvt. Ltd. All rights reserved.
        </p>

        {/* <ul className="list-unstyled d-flex">
          <li className="ms-3">
            <a className="link-dark" href="#">
              <svg className="bi" width="24" height="24">
                <use xlinkHref="#twitter" />
              </svg>
            </a>
          </li>
          <li className="ms-3">
            <a className="link-dark" href="#">
              <svg className="bi" width="24" height="24">
                <use xlinkHref="#instagram" />
              </svg>
            </a>
          </li>
          <li className="ms-3">
            <a className="link-dark" href="#">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                class="bi bi-facebook"
                viewBox="0 0 16 16"
              >
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
              </svg>
            </a>
          </li>
        </ul> */}
      </div>
    </footer>
  );
}

export default Footer;
