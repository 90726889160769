import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/css/headers.css';
import './assets/css/features.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

reportWebVitals();
