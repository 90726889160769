import { useNavigate } from "react-router-dom";

export default function Home({ mode }) {
  let navigate = useNavigate();
  const goToKnowMorePage = (title) => {
    let path = `/about-us`;
    navigate(path);
  };

  return (
    <>
      <div className="col-md-12">
        <div className="container col-xxl-8 px-4">
          <div className="row flex-lg-row-reverse align-items-center">
            <div className="col-10 col-sm-8 col-lg-6">
              <img
                src="../images/intro.jpg"
                className="d-block mx-lg-auto img-fluid"
                alt="Bootstrap Themes"
                width="700"
                height="500"
                loading="lazy"
              />
            </div>
            <div className="col-lg-6">
              <h1 className="display-5 fw-bold lh-1 mb-3">
                Welcome to Vishleshak Technologies!
              </h1>
              <p className="lead">
                We are a software services company, offering comprehensive and
                cutting-edge solutions to businesses of all sizes across various
                industries. Our team of highly skilled professionals is
                dedicated to helping clients achieve their digital
                transformation goals through custom software development,
                software integration, cloud services, and mobile app
                development.
              </p>
              <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                <button
                  type="button"
                  className="btn btn-primary btn-lg px-4 me-md-2"
                  onClick={() => goToKnowMorePage()}
                >
                  Know us more...
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
