import { useForm } from "react-hook-form";

export default function ContactUs({ mode }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // const onSubmit = data => {

  //   Axios.post(`${process.env.REACT_APP_API_URL}/`,data,{ headers:{
  //     "Content-Type":"application/json",
  //     "Accept":"application/json",
  //     "Authorization":"Bearer "+JSON.parse(localStorage.getItem("user_info")).access_token
  //   }} )
  //   .then((response)=> {
  //     if(response.data.statusCode==200 || response.data.statusCode==201 ){
  //       toast.success(response.data.message, {
  //         position: toast.POSITION.TOP_CENTER,
  //         autoClose:1500,

  //       });
  //     }})
  //   .catch((error) => {
  //     if(error.data.statusCode!=200 || error.data.statusCode!=201 ){
  //       toast.error(error.data.message, {
  //         position: toast.POSITION.TOP_CENTER,
  //         autoClose:1500,
  //       });
  //     }}
  //   );

  // };

  const onSubmit = (data) => {
    alert("data submitted", data);
    console.log("data", data);
    reset();
  };
  return (
    <>
      <div
        className="col-xl-12 col-xxl-8 px-4 py-5"
        // style={{ color: mode === "dark" ? "white" : "black" }}
      >
        {/* <div className="row align-items-center g-lg-5 py-5"> */}
        <div className="row align-items-center" style={{ width: "100%" }}>
          <div className="col-lg-7 text-center text-lg-start">
            <h1 className="display-4 fw-bold lh-1 mb-3">
              Send us a message...
            </h1>
            <p className="col-lg-10 fs-4">
              To learn more about our software services and how we can help your
              business, please write us at{" "}
              <a href="mailto:sayhi@vishleshak.io">sayhi@vishleshak.io</a> or fill up and submit the form. We
              would be happy to schedule a consultation with you to discuss your
              specific needs and provide a customized solution.
            </p>
          </div>
          <div className="col-md-10 mx-auto col-lg-5">
            <form
              className="p-4 p-md-8 border rounded-3 bg-light"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="col-sm-12 mt-2">
                <label htmlFor="name">Name</label>
                <input
                  id="name"
                  type="text"
                  name="name"
                  autoComplete="off"
                  className={`form-control ${errors.name ? "is-invalid" : ""}`}
                  placeholder="Name"
                  {...register("name", {
                    required: "Name is required",
                    pattern: {
                      value: /^[a-zA-Z ]+$/,
                      message: "Name must be a valid string",
                    },
                    minLength: {
                      value: 3,
                      message: "Name should be greater than 3 characters",
                    },
                    maxLength: {
                      value: 20,
                      message: "Name shouldn't be greater than 20 characters",
                    },
                  })}
                />
                <div className="invalid-feedback">{errors?.name?.message}</div>
              </div>

              <div className="col-sm-12 mt-2">
                <label htmlFor="email">Email address</label>
                <input
                  type="email"
                  autoComplete="off"
                  className={`form-control ${errors.email ? "is-invalid" : ""}`}
                  placeholder="Email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                      message: "Email must be a valid email address",
                    },
                  })}
                />
                <div className="invalid-feedback">{errors?.email?.message}</div>
              </div>

              <div className="col-sm-12 mt-2">
                <label htmlFor="contact">Contact</label>
                <input
                  type="tel"
                  name="contact_no"
                  autoComplete="off"
                  className={`form-control ${
                    errors.contact_no ? "is-invalid" : ""
                  }`}
                  placeholder="Contact"
                  {...register("contact_no", {
                    required: "Contact Number is required",
                    validate: (value) => value.length > 9 && value.length < 11,
                    minLength: {
                      value: 10,
                      message: "Contact number should be 10 digit",
                    },
                    maxLength: {
                      value: 10,
                      message: "Name shouldn't be greater than 10 digit",
                    },
                  })}
                />
                <div className="invalid-feedback">
                  {errors?.contact_no?.message}
                </div>
              </div>

              <div className="col-sm-12 mt-2">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  rows="3"
                  name="message"
                  className={`form-control ${
                    errors.message ? "is-invalid" : ""
                  }`}
                  placeholder="Message"
                  {...register("message", {
                    required: "Message is required",
                    pattern: {
                      value: /^[a-zA-Z ]+$/,
                      message: "Message must be a valid string",
                    },
                    minLength: {
                      value: 3,
                      message: "Message should be greater than 10 characters",
                    },
                    maxLength: {
                      value: 20,
                      message:
                        "Message shouldn't be greater than 50 characters",
                    },
                  })}
                />

                <div className="invalid-feedback">
                  {errors?.message?.message}
                </div>
              </div>
              <button
                className="w-100 btn btn-lg btn-primary mt-5"
                type="submit"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
