import { Link, useParams } from "react-router-dom";
import IntroBlog from "./java-blogs/intro-blog";
import JavaBenefitsBlog from "./java-blogs/benefits-of-java";
import { useState } from "react";
import IntroOOP from "./java-blogs/intro-oop";
import ReactExpalin from "./react-blogs/what-is-react";
import UnderstandingBasics from "./react-blogs/understanding-basics";
import Steps from "./react-blogs/step-by-step";
import ReactAngular from "./react-blogs/react-angular";
import ReactPractices from "./react-blogs/react-practices";
import ReactBenefits from "./react-blogs/react-benefits";
import ReactTools from "./react-blogs/react-tools";
import ReactRedux from "./react-blogs/react-redux";
import ReactOptimization from "./react-blogs/react-optimization";
import ReactMistakes from "./react-blogs/react-mistakes";
import ReactIntegrate from "./react-blogs/react-integrate";
import ReactRealtimeApp from "./react-blogs/react-realtime-app";
import ServerSideRendering from "./react-blogs/server-side-rendering";
import UnderstandingHooks from "./react-blogs/understanding-hooks";
import ReactNative from "./react-blogs/react-native";
import ReactBestPractices from "./react-blogs/react-best-practices";
import ReactTrends from "./react-blogs/react-trends";
import ReactSEO from "./react-blogs/react-seo";
import ReactEcommerce from "./react-blogs/react-ecom";

import NodeFeatures from "./node-blogs/node-features";
import NodeServer from "./node-blogs/server-with-node";
import NodeModules from "./node-blogs/node-modules";
import NodeCompare from "./node-blogs/node-compare";
import NodeBenefits from "./node-blogs/node-benefits";
import NodeDebugging from "./node-blogs/node-debugging";
import NodeWithWebsocket from "./node-blogs/node-with-websocket";
import NodeApplications from "./node-blogs/node-applications";
import NodeSecurity from "./node-blogs/node-security";
import NodeArchitecture from "./node-blogs/node-architecture";
import NodeTesting from "./node-blogs/node-testing";
import NodeDeployment from "./node-blogs/node-deployment";
import NodeNosqlDb from "./node-blogs/node-nosql";
import NodeApiPrinciples from "./node-blogs/node-apis-principles";
import NodewithChatbot from "./node-blogs/node-with-chatbot";
import NodeMachineLearning from "./node-blogs/node-machine";

import AdvancePHPTechniques from "./php-blogs/adv-php-techniques";
import DebuggingWithPhp from "./php-blogs/debugging-with-php";
import PhpWithOtherTechnologies from "./php-blogs/php-with-other-technologies";
import RestApisPHP from "./php-blogs/rest-apis-php";
import WebAppWithPHP from "./php-blogs/webapp-with-php";

import CreateManageTables from "./mysql-blogs/create-manage-tables";
import IntroMysql from "./mysql-blogs/intro-mysql";
import MysqlBackupsAndRecovery from "./mysql-blogs/mysql-backups-and-recovery";
import MysqlComparison from "./mysql-blogs/mysql-comparison";
import MysqlDatatypes from "./mysql-blogs/mysql-datatypes";
import MysqlIndexes from "./mysql-blogs/mysql-indexes";
import MysqlPerformanceTuning from "./mysql-blogs/mysql-performance-tuning";
import MysqlQueries from "./mysql-blogs/mysql-queries";
import MysqlScaling from "./mysql-blogs/mysql-scaling";
import MysqlSecurity from "./mysql-blogs/mysql-security";


import PythonIntro from "./python-blogs/python-intro";
import PythonAdvantages from "./python-blogs/python-advantages";
import PythonKeyFeatures from "./python-blogs/python-key-features";
import DataStructuresInPython from "./python-blogs/datastructures-in-python";
import PythonBestPractices from "./python-blogs/python-best-practices";
import PythonOOPS from "./python-blogs/python-oop";
import PythonFiles from "./python-blogs/python-files-directories";
import PythonWebDevelopment from "./python-blogs/python-web-developement";
import PythonWebScrapper from "./python-blogs/building-web-scrapper";
import PythonDataVisualisation from "./python-blogs/data-visualisation";
import PythonRestfulApi from "./python-blogs/creating-restful-api";
import PythonTesting from "./python-blogs/python-testing";
import PythonforNLP from "./python-blogs/python-for-NLP";
import PythonForDesktop from "./python-blogs/python-for-desktop";
import PythonCommonMistakes from "./python-blogs/common-mistakes";
import PythonDeployment from "./python-blogs/python-deployment";

import ReactiveIntro from "./reactive-programming/reactive-intro";
import ReactiveProgrammingBenefits from "./reactive-programming/reactive-programming-benefits";
import ReactiveKeyConcepts from "./reactive-programming/reactive-key-concepts";
import ReactiveProgrammingComparison from "./reactive-programming/reactive-imperative-comaparison.js";
import ReactiveProgrammingGuide from "./reactive-programming/reactive-guide";
import ReactiveProgramminginJava from "./reactive-programming/java-using-RxJava";
import ReactiveProgramminginJavaScript from "./reactive-programming/javascript-using-RxJS";
import ReactiveHandleBackPressure from "./reactive-programming/handle-backpressure";


export default function BlogDetails(blog) {
  const { blog_name } = useParams();

  let [blogDetails] = useState({
    //java blogs
    "Introduction to Java Programming": <IntroBlog />,
    "Benefits of using Java Programming": <JavaBenefitsBlog />,
    "Introduction to Object Oriented Programming": <IntroOOP />,
 

    //React js blogs
    "What is React JS and why should you use it":<ReactExpalin/>,
    "Understanding the basics of React JS - Components, JSX, and Virtual DOM":<UnderstandingBasics/>,
    "How to get started with React JS - A step-by-step guide":<Steps/>,
    "React JS vs. Angular - A comparison of two popular front-end frameworks":<ReactAngular/>,
    "React JS best practices - Tips and tricks for developing better applications":<ReactPractices/>,
    "The benefits of using React JS for building large-scale applications":<ReactBenefits/>,
    "How to test React JS applications - Tools and techniques":<ReactTools/>,
    "React JS and Redux - Managing state in your applications":<ReactRedux/>,
    "React JS performance optimization - Techniques for improving speed and efficiency":<ReactOptimization/>,
    "Common mistakes to avoid when developing React JS applications":<ReactMistakes/>,
    "How to integrate React JS with other technologies - A guide":<ReactIntegrate/>,
    "Building real-time applications with React JS and WebSockets":<ReactRealtimeApp/>,
    "Server-side rendering with React JS - Benefits and drawbacks":<ServerSideRendering/>,
    "Understanding React JS hooks - An introduction":<UnderstandingHooks/>,
    "React Native - Building mobile applications with React JS":<ReactNative/>,
    "How to secure React JS applications - Best practices for keeping your application safe":<ReactBestPractices/>,
    "The future of React JS - Trends and predictions":<ReactTrends/>,
    "How to use React JS for SEO - Techniques for optimizing your website for search engines":<ReactSEO/>,
    "React JS for e-commerce - A guide to building online stores with React JS":<ReactEcommerce/>,

    //Nodejs
    "Introduction to Node.js and its key features":<NodeFeatures/>,
    "Building a simple web server with Node.js and Express":<NodeServer/>,
    "Exploring Node.js modules and libraries":<NodeModules/>,
    "Node.js vs. other server-side frameworks: a comparison":<NodeCompare/>,
    "The benefits and challenges of using Node.js in production environments":<NodeBenefits/>,
    "Debugging Node.js applications: tips and tools":<NodeDebugging/>,
    "Node.js and real-time communication with WebSockets":<NodeWithWebsocket/>,
    "Building scalable and high-performance Node.js applications":<NodeApplications/>,
    "Node.js security best practices and vulnerabilities to watch out for":<NodeSecurity/>,
    "Node.js and microservices architecture: pros and cons":<NodeArchitecture/>,
    "Testing Node.js applications: strategies and frameworks":<NodeTesting/>,
    "Deploying Node.js applications to the cloud":< NodeDeployment/>,
    "Node.js and NoSQL databases: a match made in heaven":<NodeNosqlDb/>,
    "Node.js and RESTful APIs: principles and implementation":<NodeApiPrinciples/>,
    "Building a chatbot with Node.js and Natural Language Processing (NLP)":<NodewithChatbot/>,
    "Node.js and machine learning: use cases and libraries":<NodeMachineLearning/>,

    //php
    "Advanced topics like regular expressions, XML processing, and web services in PHP":<AdvancePHPTechniques/>,
    "How to troubleshoot errors and bugs in your PHP code using tools like Xdebug and the built-in debugging features of PHP":<DebuggingWithPhp/>,
    "How to build a complete web application with PHP":<PhpWithOtherTechnologies/>,
    "A guide to building APIs using popular PHP frameworks like Laravel and Slim, including handling authentication, validation, and versioning":<RestApisPHP/>,
    "How to build a complete web application with PHP":<WebAppWithPHP/>,

    //Mysql
    "How to create tables, add and remove columns, and set up relationships between tables":<CreateManageTables/>,
    "What it is, how it works, and why it's important for modern web development":<IntroMysql/>,
    "How to set up regular backups and restore from them in case of data loss or corruption":<MysqlBackupsAndRecovery/>,
    "A comparison of MySQL with other popular databases like PostgreSQL, MongoDB, and Cassandra, including their strengths and weaknesses":<MysqlComparison/>,
    "How to choose the right data type for your data, and how to work with common data types like integers, floats, strings, and dates":<MysqlDatatypes/>,
    "What they are, why they matter, and how to use them effectively to speed up database queries":<MysqlIndexes/>,
    "Best practices for optimizing MySQL performance, including server configuration, query optimization, and database design":<MysqlPerformanceTuning/>,
    "How to write SQL queries to retrieve and manipulate data, including basic SELECT statements, joins, aggregations, and subqueries":<MysqlQueries/>,
    "Strategies for scaling MySQL databases to handle increasing traffic and data volumes, including replication, sharding, and clustering":<MysqlScaling/>,
    "What it is, how it works, and why it's important for modern web development":<MysqlSecurity/>,

    //Python
    "Introduction to Python - A beginner's guide": <PythonIntro/>,
    "Advantages of using Python for machine learning and data science":<PythonAdvantages/>,
    "Key features of Python 3 and their importance":<PythonKeyFeatures/>,
    "Common data structures in Python and their uses":<DataStructuresInPython/>,
    "Best practices for writing efficient and clean Python code":<PythonBestPractices/>,
    "Understanding Object-Oriented Programming (OOP) in Python":<PythonOOPS/>,
    "How to work with files and directories in Python":<PythonFiles/>,
    "Using Python for web development - An overview":<PythonWebDevelopment/>,
    "Building a web scraper with Python":<PythonWebScrapper/>,
    "How to use Python for data visualisation":<PythonDataVisualisation/>,
    "Creating a RESTful API with Python":<PythonRestfulApi/>,
    "Best practices for testing Python applications":<PythonTesting/>,
    "Using Python for natural language processing (NLP)":<PythonforNLP/>,
    "How to use Python for desktop applications":<PythonForDesktop/>,
    "Common mistakes to avoid when developing Python applications":<PythonCommonMistakes/>,
    "How to deploy Python applications on cloud platforms":<PythonDeployment/>,

    //Reactive Programming
    "Introduction to Reactive Programming - What is it":<ReactiveIntro/>,
    "The benefits of Reactive Programming in modern software development":<ReactiveProgrammingBenefits/>,
    "Key concepts of Reactive Programming - Observables, Observers, and Subscribers":<ReactiveKeyConcepts/>,
    "Reactive Programming vs. Imperative Programming - A comparison":<ReactiveProgrammingComparison/>,
    "Getting started with Reactive Programming - A beginner's guide":<ReactiveProgrammingGuide/>,
    "Reactive Programming in Java - Using RxJava":<ReactiveProgramminginJava/>,
    "Reactive Programming in JavaScript - Using RxJS":<ReactiveProgramminginJavaScript/>,
    "How to handle backpressure in Reactive Programming":<ReactiveHandleBackPressure/>



  });

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <Link to="/home">Home</Link>
          </li>
          <li class="breadcrumb-item">
            <Link to="/blogs">Blogs</Link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Blog Topic Name
          </li>
        </ol>
      </nav>
      <div className="col-md-12">{blogDetails[blog_name]}</div>
      {/* <div className="col-md-12">

      </div> */}
    </>
  );
}
