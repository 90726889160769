import React from "react";

export default function UnderstandingHooks() {
  return (
    <>
      <div>
        <h4>Understanding React JS hooks - An introduction.</h4>
        <hr />
        <div>
          <p>
            React JS hooks are a feature introduced in React version 16.8 that
            allows developers to use state and other React features in
            functional components. Prior to hooks, state and lifecycle methods
            were only available in class components.
          </p>
          <p>
            Hooks provide a way to reuse stateful logic, instead of relying on
            higher-order components or render props. There are several built-in
            hooks that allow developers to manage state, effects, and context in
            functional components.
          </p>

          <p>Here are some of the most commonly used hooks in React JS:</p>

          <h4>useState()</h4>
          <p>
            This hook allows you to manage state in functional components. It
            returns an array with two values: the current state value and a
            function to update that value.
          </p>
          <h4>useEffect()</h4>
          <p>
            This hook allows you to perform side effects, such as fetching data
            or manipulating the DOM, in functional components. It takes a
            function as an argument that will be called after every render.
          </p>
          <h4>useContext()</h4>
          <p>
            This hook allows you to consume context in functional components. It
            takes a context object as an argument and returns the current
            context value.
          </p>
          <h4>useRef()</h4>
          <p>
            This hook allows you to create a mutable value that persists across
            renders. It returns an object with a current property that can be
            mutated.
          </p>
          <h4>useCallback()</h4>
          <p>
            This hook allows you to memoize a function so that it is only
            re-created when one of its dependencies changes. This can help with
            performance when passing functions down to child components.
          </p>
          <h4>useMemo()</h4>
          <p>
            This hook allows you to memoize a value so that it is only
            re-computed when one of its dependencies changes. This can help with
            performance when performing expensive calculations.
          </p>
          <p>
            Using hooks in React JS can simplify the development process and
            make functional components more powerful. However, it's important to
            understand the rules and limitations of hooks, such as not using
            hooks conditionally or in loops, and following the recommended order
            of hooks.
          </p>
        </div>
      </div>
    </>
  );
}
