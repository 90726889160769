export default function ReactiveKeyConcepts() {
  return (
    <div>
      <h4>
        Key concepts of Reactive Programming - Observables, Observers, and
        Subscribers
      </h4>
      <hr />
      <div>
        <p>
          Observables, observers, and subscribers are key concepts in reactive
          programming:
        </p>

        <h4>Observables</h4>
        <p>
          Observables are sources of data that emit events over time. They can
          emit a stream of values or a single value, and they can also emit
          errors and completion signals. Observables are passive in nature and
          do not emit events until they have at least one subscriber.
        </p>

        <h4>Observers</h4>
        <p>
          Observers are entities that consume the events emitted by observables.
          They can react to the emitted values, errors, and completion signals.
          Observers are passive in nature and do not emit any events of their
          own
        </p>

        <h4>Subscribers</h4>
        <p>
          Subscribers are entities that create a subscription to an observable.
          They receive the events emitted by the observable and can react to
          them as needed. Subscribers can also unsubscribe from the observable
          when they no longer need to receive events.
        </p>

        <p>
          In reactive programming, observables, observers, and subscribers work
          together to enable the processing of data in real-time. The observable
          emits events over time, and the observer reacts to these events. The
          subscriber creates a subscription to the observable and receives the
          events emitted by the observable.
        </p>
        <p>
          By using these key concepts, developers can create reactive systems
          that can handle large amounts of data and traffic, and respond to
          changes in real-time.
        </p>
      </div>
    </div>
  );
}
