import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Blogs() {
  let [blogs] = useState([
    { name: "Java", image: "../../images/java.png" },
    { name: "ReactJs", image:  "../../images/React.png" },
    { name: "NodeJs", image: "../../images/node.png" },
    { name: "Python", image: "../../images/python.png" },
    { name: "Php", image: "../../images/php.png"},
    { name: "Mysql", image: "../../images/mysql.png" },
    { name: "ReactiveProgramming", image: "../../images/reactiveprogramming.png" },
    // { name: "Data Analysis", image: "../../images/dataanalysis.png" },
    // { name: "Flutter", image: "../../images/flutter.png" },
    // { name: "React Native", image: "" },
    
  
  ]);

  let navigate = useNavigate();
  const goToBlogList = (title) => {
    let path = `/blogs/${title}`;
    navigate(path);
  };

  return (
    <>
      <div className="col-md-12">
        <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
          {blogs &&
            blogs.map((item, index) => {
              return (
                <div className="col">
                  <div
                    className="card card-cover h-100 overflow-hidden rounded-4"
                    style={{
                      backgroundImage: `url(${item.image})`,
                      backgroundSize: "cover",
                      height: "350px",
                      width: "300px",
                    }}
                    onClick={() => goToBlogList(item.name)}
                  >
                    <div className="d-flex flex-column h-100 p-5 pb-3">
                      <h3 className="pt-5 mt-5 mb-4 lh-1 fw-light text-reset" >
                        {/* {item.name} */}
                      </h3>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}
