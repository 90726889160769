import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState } from "react";
import "./App.css";
import Home from "./pages/home";
import AboutUs from "./pages/about-us";
import ContactUs from "./pages/contact-us";
import Blogs from "./pages/blogs";
import BlogsList from "./pages/blogs-list";
import BlogDetails from "./pages/blog-details";
import Header from "./pages/common/header";
import Footer from "./pages/common/footer";

function App() {
  const [mode, setMode] = useState("dark");

  const toggleMode = () => {
    if (mode === "light") {
      setMode("dark");
      document.body.style.backgroundColor = "black";
    } else {
      setMode("light");
      document.body.style.backgroundColor = "white";
    }
  };
  return (
    <Router>
      <div className="container">
        <div className="row">
          <Header selectedMenu="home" mode={mode} toggleMode={toggleMode} />
          <Routes>
            <Route path="/" element={<Home mode={mode} />} />
            <Route path="/home" element={<Home mode={mode} />} />
            <Route path="/about-us" element={<AboutUs mode={mode} />} />
            <Route path="/contact-us" element={<ContactUs mode={mode} />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blogs/:title" element={<BlogsList />} />
            <Route
              path="/vishleshak-technologies-blogs/:blog_name"
              element={<BlogDetails />}
            />
          </Routes>
        </div>
        <hr />
        <Footer mode={mode} />
      </div>
    </Router>
  );
}

export default App;
